import React from 'react';
import * as H from 'history';
import { createBrowserHistory } from 'history';
import moment from 'moment';

let env = {
  baseUrl: '/',
  layout: null,
};
export const Env = env;
export const history: H.History = createBrowserHistory();

export enum StatusCode {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  RequestUriTooLong = 414,
  InternalServerError = 500,
  ServiceUnavailable = 503,
}

export const api_call_get = (
  apiId: string,
  path: string,
  errorSystem: string = '',
  errorMessageByCode?: Map<StatusCode, string>,
  headers: any[] = []
): Promise<any> => {
  return api_call(
    'GET',
    null,
    apiId,
    path,
    errorSystem,
    headers,
    errorMessageByCode
  );
};

export const api_call_post = (
  apiId: string,
  path: string,
  errorSystem: string,
  body: any,
  headers: any[] = []
): Promise<any> => {
  return api_call('POST', body, apiId, path, errorSystem, headers);
};

export const api_call_put = (
  apiId: string,
  path: string,
  errorSystem: string,
  body: any,
  headers: any[] = []
): Promise<any> => {
  return api_call('PUT', body, apiId, path, errorSystem, headers);
};

export const api_call_delete = (
  apiId: string,
  path: string,
  errorSystem: string = '',
  headers: any[] = []
): Promise<any> => {
  return api_call('DELETE', null, apiId, path, errorSystem, headers);
};

const api_call = async (
  method: string,
  body: any,
  apiId: string,
  path: string,
  errorSystem: string,
  headers: any[] = [],
  errorMessageByCode?: Map<StatusCode, string>
): Promise<any> => {
  let url = `${Env.baseUrl}call/${apiId}${path}`;

  // const webRequestUrl = _utils.upsertApiParams(baseUrl, 'clientType', ERquestClientType.WebClient)
  // const url = _utils.upsertApiParams(webRequestUrl, '_hid', document['requestKey'])

  let _headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (headers.length > 0) {
    headers.map((header) => {
      _headers[header.name] = header.value;
    });
  }

  // debugger

  try {
    let rp = await fetch(url, {
      method: method,
      body: body ? JSON.stringify(body) : null,
      credentials: 'include',
      headers: _headers,
    });
    if (rp.status == 401) {
      if (env.layout) {
        env.layout.openPopupAuthorizeException();
        // await checkRefreshSessionFinish();
        return await api_call(
          method,
          body,
          apiId,
          path,
          errorSystem,
          headers,
          errorMessageByCode
        );
      }
    } else if (rp.status == 403) {
      if (env.layout) env.layout.redirectPagePermission(null);
    } else if (rp.status == StatusCode.RequestUriTooLong) {
      const message =
        errorMessageByCode?.get(StatusCode.RequestUriTooLong) ||
        'Không thể thực hiện do yêu cầu quá dài. Vui lòng thử lại sau.';
      // _utils.ShowError(message)
    } else if (rp.status == 200 || rp.status == 201 || rp.status == 304) {
      if (
        rp.headers.get('X-Haraworks-RequestData') &&
        rp.headers.get('X-Haraworks-RequestData') !=
        `${document['user'].OrgId}:${document['user'].Id}`
      ) {
        if (env.layout)
          env.layout.redirectPagePermission(
            rp.headers.get('X-Haraworks-RequestKey')
          );
        return {
          data: { data: [], length: 0 },
          errors: true,
          totalCount: 0,
          message: 'Bạn không có quyền để thực hiện thao tác này!',
        };
      }
      return await rp.json();
    } else {
      if (errorSystem) {
        // _utils.ShowError(errorSystem)
      }
    }
  } catch (e) {
    // _utils.ShowError('Xin lỗi, đã có lỗi khi thực hiện')

    throw e;
  } finally {
  }
};

export const api_upload_file = (blob: Blob, fileName: string): Promise<any> => {
  let url = `${Env.baseUrl}file/upload`;
  let input = new FormData();
  input.append('file', blob, fileName);

  return fetch(url, {
    method: 'POST',
    body: input,
    credentials: 'include',
  }).then((rsp) => (rsp != null ? rsp.json() : null));
};

export const fortmatCurrency = (value, format) => {
  var x = value;
  x = x.toLocaleString('it-IT', { style: 'currency', currency: format });
  return x;
};
export const convertToBillion = (number, isGetUnit = false, unitFix = 'Tỷ') => {
  var unit = '';
  if (isGetUnit == true) {
    unit = getUnitCurrency(number);
    unit = ' ' + unit;
  }
  if (!number) return null;
  if (number >= 1000000000 || unitFix == 'Tỷ') {
    return (number / 1000000000).toFixed(2) + unit;
  } else {
    if (number < 1000000000) return convertMilionToBilion(number) + unit;
  }
};
export const convertMilionToBilion = (number, isGetUnit = false) => {
  var unit = '';
  if (isGetUnit == true) {
    unit = ' ' + getUnitCurrency(number);
  }
  if (!number) return null;
  if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(2) + unit;
  } else if (number >= 1000000000) {
    return (number / 1000000000).toFixed(2) + unit;
  } else if (number < 1000000) return (number / 1000000).toFixed(2);
};
export const getUnitCurrency = (number, unit = 'Tỷ') => {
  if (!number) {
    return 'Triệu';
  }
  if (number >= 1000000000) {
    return 'Tỷ';
  } else {
    return 'Triệu';
  }
};
export const convertToNumber = (billionString, unit = 'tỷ') => {
  // Tách phần số và đơn vị tỷ

  // Lấy phần số, loại bỏ dấu ","
  let number = billionString;
  // Kiểm tra đơn vị
  if (unit === 'tỷ' || unit === 'tỉ') {
    // Chuyển đổi số thành tỷ
    return number * 1000000000;
  } else {
    // Trường hợp không phải tỷ, trả về số ban đầu
    return number * 1000000;
  }
};
export const getDateShort = (
  inputDate: Date,
  dafaultFormat: string = 'DD/MM/YYYY HH:mm'
): string => {
  if (!inputDate) {
    return null;
  }
  return moment(inputDate).format(dafaultFormat);
};
export const getDateShortUTC = (
  inputDate: any,
  dafaultFormat: string = 'DD/MM/YYYY HH:mm'
): string => {
  if (!inputDate) {
    return null;
  }
  if (typeof inputDate == 'object') {
    inputDate = getDateShort(inputDate);
  }
  if (inputDate.toLowerCase().indexOf('z') < 0) {
    inputDate = inputDate + 'Z';
  }
  return inputDate ? moment(inputDate).local().format(dafaultFormat) : null;
};

export class WheelNavigator {
  private items: string[];
  private currentIndex: number;

  constructor(items: string[]) {
    this.items = items;
    this.currentIndex = 0;
  }

  nextItem(): string {
    this.currentIndex = (this.currentIndex + 1) % this.items.length;
    return this.items[this.currentIndex];
  }

  previousItem(): string {
    this.currentIndex =
      (this.currentIndex - 1 + this.items.length) % this.items.length;
    return this.items[this.currentIndex];
  }
}
export const pushParamToUrl = (params) => {
  let search = (window.location.pathname.indexOf('?') < 0 ? '?' : '&') + serialize(params)
  // if (value === '' || value === null)
  //   return;
  var url = window.location.href;
  //const searchParams = new URLSearchParams(new URL(url).search);
  //searchParams.set(key, (value));
  const newUrl = new URL(url);
  history.push(newUrl.pathname.toString() + search)
  return newUrl.toString();
}

export const resetParam = () => {

  var url = window.location.href;
  const newUrl = new URL(url);
  history.push(newUrl.pathname)
  return newUrl.toString();
}
export const getParams = (item) => {
  var pairs = item.substring(1).split("&"),
    obj = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}
export const getSearchParams = () => {
  var pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}
export const IsNullOrEmpty = (value: any) => {
  if (value === 0) {
      return false;
  }
  if (value == undefined) {
      return true;
  }
  if (value == null) {
      return true;
  }


  if (value == '' && value !== 0 && value !== false) {
      return true;
  }
  if (value.trim && value.trim() === '') {
      return true;
  }
  return false;
}
export const serialize = (obj) => {
  var str = [];
  for (var p in obj)
      if (obj.hasOwnProperty(p) && (!IsNullOrEmpty(obj[p]))) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
  return str.join('&');
}
export const removeAccents = (str) => {
  if (IsNullOrEmpty(str))
      return str;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
}