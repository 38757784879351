import React, { useEffect } from 'react'
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import useLocalStorage from 'hooks/useStorage';

export const SignIn = () => {
    const { setAuth } = useAuth();
    const CLIENT_URL = "http://localhost:3000/"
    const LOGIN_URL = "https://accounts.namdaivu.com.vn/connect/authorize?response_type=token&client_id=tech_land_client&redirect_uri={http://localhost:5005/admin/signin-oidc&scope=tl_api"

    useEffect(() => {

        var isProduction = window.location.host == "namdaivu.com.vn" ? true : false;
        var hostAccount = isProduction == true ? "https://accounts.namdaivu.com.vn" : "https://acc.namdaivu.com.vn";
        let directUrl = `${hostAccount}/connect/authorize?response_type=token&client_id=tech_land_client&redirect_uri=${window.location.origin}/admin/signin-oidc&scope=tl_api`

        window.location.replace(directUrl)
    }, [])

    return (
        <div></div>
    )
}
