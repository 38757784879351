
import getLocalValue from 'hooks/useStorage'
import React, { useEffect } from 'react'
import useAuth from '../../hooks/useAuth';

export const RedirectPage = () => {
    let returnUrl = JSON.parse(localStorage.getItem('return_url'))
    const { auth, setAuth } = useAuth();
    useEffect(() => {
        getTokenFromPath()
        // returnUrl = 'http://localhost:3000'

        // setTimeout(() => {
        //     window.location.replace(returnUrl)
        // }, 5000)
        window.location.replace(returnUrl)
        // setTimeout(() => {
        //     window.location.href = returnUrl
        // }, 5000)
        // window.location.href = returnUrl
        // setTimeout(() => {
        //     navigate(returnUrl)
        // }, 1000)
    }, [])
    const getTokenFromPath = () => {
        let params = window.location.href.split('#')[1]
        let accessToken = params?.split('&')[0]
        let accessTokenValue = accessToken?.split('=')[1]
        setAuth(prev => {
            let newAuth = {
                ...prev,
                accessToken: accessTokenValue
            }
            localStorage.setItem('auth', JSON.stringify(newAuth));
            localStorage.setItem('token', JSON.stringify(accessTokenValue));
            return newAuth
        });
        // setCurrentAuth(accessToken)
        // setAuth({ accessToken })
    }

    //   setToken(){
    //     if (path.indexOf(config.pathAdmin + 'loading') > -1) {
    //         let token = this.getTokenFromPath();
    //         if (token !== '') {
    //           localStorage.setItem('AccessToken', token);
    //           window.location.href = '/' + config.pathAdmin;
    //         } else {
    //           redirect = true;
    //         }
    //       }
    // }
    return (
        <div>index</div>
    )
}
