import request from '../utils/axiosService'

const URL_GET_ALL = '/department/getall'

export const getAllDepartment = (param) => {
    return request({
        url: URL_GET_ALL,
        method: 'get',
        params: param
    })
}
