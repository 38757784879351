
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import * as H from 'history';
import './index.css';
import { MdOutlineCancel } from "react-icons/md";

import { Link, NavLink, useLocation } from "react-router-dom";
import {
    AppstoreOutlined,
    CalendarOutlined,
    LinkOutlined,
    MailOutlined,
    SettingOutlined,


    ContainerOutlined,
    DesktopOutlined,

    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined

} from '@ant-design/icons';
import { Layout, Divider, Menu, Switch, theme, Button } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import type { GetProp, MenuProps } from 'antd';
import { isMobile } from 'react-device-detect';
import useAuth from 'hooks/useAuth';
import { useStateContext } from 'Contexts/ContextProvider';
import { AiOutlineMenu } from 'react-icons/ai';
import { Navbar } from 'components/Navbar';
import { useOnClickOutside } from 'hooks/useClickOutSide';
import { Sidebar } from 'components/Sidebar';


type MenuTheme = GetProp<MenuProps, 'theme'>;
type MenuItem = GetProp<MenuProps, 'items'>[number];
const { Header, Sider, Content } = Layout

export const LayoutPartial = (props: any) => {
    const { activeMenu, setActiveMenu, screenSize, currentColor, setCurrentActiveMenu, getMe, profile } = useStateContext();

    const [mode, setMode] = useState<'vertical' | 'inline'>('inline');
    const [loading, setLoading] = useState(false)
    const [isMobileDevice, setIsMobileDevice] = useState(false)
    const [isClickMenuMobile, setIsClickMenuMobile] = useState(false)
    const { auth } = useAuth();
    const location = useLocation();
    const ref = useRef();
    // useOnClickOutside(ref, () => setCurrentActiveMenu());

    useEffect(() => {
        const isAccess = auth?.accessToken
        if (isAccess)
            getMe();

        if (isAccess) {

            setActiveMenu(true)
        }
        if (isMobile) {

            setActiveMenu(false)
        }
        setIsMobileDevice(isMobile)
    }, [])
    const handleCloseSidebar = () => {
        if (activeMenu && screenSize <= 900) {

            setActiveMenu(false)
        }
    }

    const handleClickMenuMobile = () => {

        setIsClickMenuMobile(!isClickMenuMobile)
    }

    const handleClickShowMenu = () => {

        setActiveMenu(activeMenu)
    }

    if (loading)
        return <div>Loading</div>;

    const NavButton = () => {
        // return < TooltipComponent content={title} position="BottomCenter" >
        const icon = <AiOutlineMenu />
        // </TooltipComponent >
        return <button
            type="button"
            onClick={() => { setActiveMenu(true) }}
            // style={{ color: currentColor }}
            className="relative text-xl p-3 hover:bg-light-gray"
        >
            {icon}
        </button>
    }

    const checkRender = () => {
        if (location.pathname.includes('/home'))
            return false
        return true
    }

    return (
        <div className='ui-body'>
            <div className={classnames({
                'ui-wrapper': true,
                'eform-ui-mobile': isMobileDevice,
                'is-show-menu-mobile': isClickMenuMobile
            })}>
                <React.Fragment>
                    {isClickMenuMobile && <div className='hrv-btn-close-mobile--eform' onClick={() => handleClickMenuMobile()}>
                        <button className='hrv-btn hrv-btn-default hrv-btn-close' type='button'>
                        </button>
                    </div>}
                </React.Fragment>
                <div className="fixed justify-content-end md:static bg-main-bg dark:bg-main-dark-bg navbar w-full z-2">
                    <Navbar />
                </div>
                {activeMenu &&
                    <>
                        {activeMenu ? (
                            <div ref={isMobile ? ref : null} className="menu-container sidebar dark:bg-secondary-dark-bg bg-white " style={{ width: isMobile && activeMenu ? '240px' : '60px' }}>
                                <Sidebar />
                            </div>
                        ) : (
                            <div className="w-0 dark:bg-secondary-dark-bg">
                                <Sidebar />
                            </div>
                        )}
                    </>
                }
                <main className='ui-main dark:bg-main-dark-bg'>
                    {/* <div className='wrapper-content'> */}
                    {props.children}
                    {/* </div> */}
                </main>
                {isClickMenuMobile && <div className='hrv-layout-backdrop' onClick={() => handleClickMenuMobile()}></div>}
            </div>
            <div className="ui-backdrop" onClick={() => handleClickShowMenu()}></div>
        </div>
    )
}
