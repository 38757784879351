import request from '../utils/axiosService'

const URL_EMPLOYEE_PAGING = '/employee/paging';
const URL_ADD_EMPLOYEE = '/employee/add';
const URL_UPDATE_EMPLOYEE = '/employee/update';

export const getMeInfo = () => {
    return request({
        url: '/me',
        method: 'get'
    })
}

export const getEmployeePaging = (params?: any) => {
    return request({
        url: URL_EMPLOYEE_PAGING,
        method: 'get',
        params,
    });
};

export const addEmployee = (data?: any) => {
    return request({
        url: URL_ADD_EMPLOYEE,
        method: 'post',
        data,
    });
};

export const updateEmployee = (data?: any) => {
    return request({
        url: URL_UPDATE_EMPLOYEE,
        method: 'post',
        data,
    });
};
