import React, { useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { isMobile } from 'react-device-detect';

import avatar from '../../Data/avatar.jpg';
import { Notification, UserProfile } from '../index';
import { useStateContext } from '../../Contexts/ContextProvider';
import { getMeInfo } from 'repositories';
import useAuth from 'hooks/useAuth';
import { IEmployee } from 'interfaces';
import './index.css'
import { log } from 'console';

interface INavButtonProps {
    title?: string
    customFunc?: Function
    icon?: any
    color?: any
    dotColor?: any
    fontSize?: any
}
const NavButton = (props: INavButtonProps) => {
    const { title, customFunc, icon, color, dotColor, fontSize } = props

    return <button
        type="button"
        onClick={() => customFunc()}
        style={{ color, fontSize: fontSize ? fontSize : '16px' }}
        className="relative rounded-full p-3 hover:bg-light-gray"
    >
        {/* <span
            style={{ background: dotColor }}
            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        /> */}
        {icon}
    </button>
}
export const Navbar = () => {
    const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
    const { auth, setAuth } = useAuth()
    const [profile, setProfile] = useState<IEmployee>(null)
    useEffect(() => {
        if (auth?.accessToken) {
            getMe()
        }

        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (screenSize <= 900) {
            setActiveMenu(false);
        } else {
            setActiveMenu(true);
        }
    }, [screenSize]);

    const handleActiveMenu = () => setActiveMenu(!activeMenu);

    const getMe = async () => {
        const rsp = await getMeInfo();
        if (rsp && rsp.data) {
            let data = rsp.data.data
            setAuth(prev => {
                return {
                    ...prev,
                    ...{ roles: data.roles }
                }
            });
            setProfile(data)
        }
    };

    console.log('profile: ', profile)

    return (
        <React.Fragment>
            <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
                {/* {isMobile && <div className=''><NavButton title="Menu" fontSize={'20px'} customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} /></div>} */}

                <div className="flex">
                    {/* {!isMobile && <NavButton title="Notification" fontSize={'30px'} dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')} color={currentColor} icon={<RiNotification3Line />} />} */}

                    <div
                        className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                        onClick={() => handleClick('userProfile')}
                    >
                        <img
                            className="rounded-full w-16 h-16"
                            src={avatar}
                            alt="user-profile"
                        />
                        <div className='profile-name'>
                            <span className="text-gray-400 ">Xin chào,</span>{' '}
                            <span className="ml-1">
                                {profile?.username}
                            </span>
                        </div>
                        <MdKeyboardArrowDown className="text-gray-400" style={{ fontSize: '16px' }} />
                    </div>

                    {isClicked.notification && (<Notification />)}
                    {isClicked.userProfile && (<UserProfile />)}
                </div>
            </div>
            {/* {isMobile && <div className=''> <NavButton title="Notification" fontSize={'40px'} dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')} color={currentColor} icon={<RiNotification3Line />} /></div>} */}
            {isMobile && <div className=''><NavButton title="Menu" fontSize={'40px'} customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} /></div>}
        </React.Fragment>
    );
};