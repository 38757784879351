import { useContext, useDebugValue } from "react";
import { StateContext } from "../Contexts/ContextProvider";

const useAuth = () => {
    const auth: any = useContext(StateContext);
    // console.log('auth: ', auth)
    // useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out")
    return useContext(StateContext);
}

export default useAuth;