import request from '../utils/axiosService'

const URL_GET_CITY_ALL = '/city/all'
const URL_GET_DISTRICT_ALL = '/district/all'
const URL_GET_WARD_ALL = '/ward/all'

export const getCities = (param) => {
    return request({
        url: URL_GET_CITY_ALL,
        method: 'get',
        params: param
    })
}
export const getDistricts = (param) => {
    return request({
        url: URL_GET_DISTRICT_ALL,
        method: 'get',
        params: param
    })
}
export const getWards = (param) => {
    return request({
        url: URL_GET_WARD_ALL,
        method: 'get',
        params: param
    })
}

export const getListWardByDistrictIds = (param) => {
    return request({
        url: '/ward/list',
        method: 'get',
        params: param
    })
}

export const getAllDistrict = () => {
    return request({
        url: 'district/listall',
        method: 'get'
    })
}