import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { Button } from '../index';
import { userProfileData } from '../../Data/dummy';
import { useStateContext } from '../../Contexts/ContextProvider';
import avatar from '../../Data/avatar.jpg';
import Cookies from "js-cookie";
import './index.css';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { closeIcon, logoutIcon } from '../../utils/icon'

export const UserProfile = () => {
    const { setIsClicked, initialState } = useStateContext();

    return (
        <div className="user-profile shadow nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
            <div className="flex justify-between items-center">
                <div className="flex items-center font-semibold dark:text-gray-200">Thông tin tài khoản</div>
                <span onClick={() => setIsClicked(initialState)}>{closeIcon()}</span>
            </div>
            <div className="flex justify-content-center gap-5 items-center mt-6 border-color border-b-1 pb-6">
                <img
                    className="rounded-full h-20 w-20"
                    src={avatar}
                    alt="user-profile"
                />
            </div>
            <div>

                <div className="flex border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
                    style={{ gap: '1.25rem' }}>
                    <button
                        type="button"
                        style={{ color: '#03C9D7', backgroundColor: '#E5FAFB' }}
                        className=" text-xl rounded-lg p-3 hover:bg-light-gray"
                    >
                        <BsFileEarmarkPerson />
                    </button>
                    <div>
                        <p className="font-semibold dark:text-gray-200"> Nam Đại Vũ </p>
                        <p className="text-gray-500 font-semibold dark:text-gray-400">  Quản lý   </p>
                        <p className="text-gray-500 font-semibold dark:text-gray-400"> hi@namdaivu.com.vn </p>
                    </div>
                </div>
            </div>
            <div className="d-flex mt-5" onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("auth");
                Cookies.remove(".AspNetCore.Cookies");
                //window.location.href = "/";
                var isProduction = window.location.host == "namdaivu.com.vn" ? true : false;
                var hostAcc = isProduction == true ? "https://accounts.namdaivu.com.vn/account/logout" : "https://acc.namdaivu.com.vn/account/logout";
                window.location.href = hostAcc + "?returnUrl=" + window.location.href;
            }}>
                {logoutIcon()}
                <span style={{ marginLeft: '8px', fontSize: '16px' }}>Đăng xuất</span>
            </div>
        </div >

    );
};