import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useLocalStorage from '../hooks/useStorage'
export const RequireAuth = (props) => {
    const { allowedRoles } = props
    const { auth } = useAuth();
    const location = useLocation();
    // let url = "https://accounts.namdaivu.com.vn/connect/authorize?response_type=token&client_id=tech_land_client&redirect_uri=namdaivu.com.vn&scope=tl_api"
    // let testUrl = "https://accounts.namdaivu.com.vn/account/login?ReturnUrl=https://accounts.namdaivu.com.vn/connect/authorize?response_type=token&client_id=tech_land_client&redirect_uri=http://localhost:3000/&scope=tl_api"
    // let testUrl = "https://accounts.namdaivu.com.vn/connect/authorize?response_type=token&client_id=tech_land_client&redirect_uri=http://localhost:3000"
    // let testUrl = `https://accounts.namdaivu.com.vn/connect/authorize?response_type=token&client_id=tech_land_client&redirect_uri=${window.localtion.host}`
    localStorage.setItem('return_url', JSON.stringify(window.location.href));
    let isAccess = auth?.accessToken
    return (
        isAccess
            ? <Outlet />
            : auth?.accessToken //changed from user to accessToken to persist login after refresh
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/admin/sigin" state={{ from: location }} replace />
    );
}