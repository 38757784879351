import request from '../utils/axiosService'

const URL_PROFILE_PAGING = '/recprofile/paging';
const URL_ADD_PROFILE = '/recprofile/add';
const URL_UPDATE_PROFILE = '/recprofile/update';



export const getProfilePaging = (params?: any) => {
    return request({
        url: URL_PROFILE_PAGING,
        method: 'get',
        params,
    });
};

export const addProfile = (data?: any) => {
    return request({
        url: URL_ADD_PROFILE,
        method: 'post',
        data,
    });
};

export const updateProfile = (data?: any) => {
    return request({
        url: URL_UPDATE_PROFILE,
        method: 'post',
        data,
    });
};
