import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
// import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../../Data/dummy";
import { useStateContext } from "../../Contexts/ContextProvider";
import logo from '../../assets/image/namdaivu-logo.png'
import './index.css'

export const Sidebar = () => {
    const { activeMenu, setActiveMenu, screenSize, currentColor, profile } = useStateContext();
    const handleCloseSidebar = () => {
        if (activeMenu && screenSize <= 900) {

            setActiveMenu(false)
        }
    }
    const activeLink = 'menu-item-link flex items-center pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
    const normalLink = 'menu-item-link flex items-center pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
    //console.log(profile)
    return (

        < div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10" >
            {activeMenu && (
                <>
                    <div style={{ marginTop: '10px' }} className="flex justify-between items-center">
                        <Link to="/"
                            style={{ margin: '0 16px 0 8px' }}
                            onClick={handleCloseSidebar}
                            className="items-center gap-3 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
                            <img src="https://techland-test.hn.ss.bfcplatform.vn/master/logo/nam_dai_vu_1x.png" />
                        </Link>
                        <button
                            type="button"
                            onClick={() => setActiveMenu(!activeMenu)}
                            style={{ color: currentColor }}
                            className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
                        >
                            <MdOutlineCancel size={'2em'} />
                        </button>
                    </div>
                    <div className="mt-10">
                        {links.filter(l => l.links.filter(a => a.roles == null || profile?.roles?.find(b => a.roles.indexOf(b) > -1) != null).length > 0).map((item) => (
                            <div key={item.title}>
                                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                                    {item.title}
                                </p>
                                {item.links.filter(a => a.roles == null || profile?.roles?.find(b => a.roles.indexOf(b) > -1) != null).map((link) => (
                                    <NavLink
                                        to={`/${link.href}`}
                                        key={link.href}
                                        onClick={handleCloseSidebar}
                                        style={({ isActive }) => ({
                                            backgroundColor: isActive ? currentColor : '',
                                            textDecoration: "none",
                                        })}
                                        className={({ isActive }) => (isActive ? activeLink : normalLink)}
                                    >
                                        <span className="menu-item-icon">{link.icon}</span>
                                        <span className="menu-item-text capitalize ">{link.name}</span>
                                    </NavLink>
                                ))}
                            </div>
                        ))}
                    </div>
                </>
            )
            }
        </div >
    );
};