import React, { createContext, useContext, useState } from 'react';
import { getMeInfo } from 'repositories';
import { IEmployee } from 'interfaces';
export const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
};

export const ContextProvider = ({ children }) => {
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState('#03C9D7');
    const [currentMode, setCurrentMode] = useState('Light');
    const [themeSettings, setThemeSettings] = useState(false);
    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [auth, setAuth] = useState(JSON.parse(localStorage.getItem('auth')));
    const [profile, setProfile] = useState(null)
    const setMode = (e) => {
        setCurrentMode(e.target.value);
        localStorage.setItem('themeMode', e.target.value);
    };

    const setColor = (color) => {
        setCurrentColor(color);
        localStorage.setItem('colorMode', color);
    };

    const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });
    const getMe = async () => {
        const rsp = await getMeInfo();
        if (rsp && rsp.data) {
            let data = rsp.data.data
            setAuth(prev => {
                return {
                    ...prev,
                    ...{ roles: data.roles }
                }
            });
            console.log("profile__" + data);
            setProfile(data)
        }
    };

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <StateContext.Provider value={{ auth, setAuth, currentColor, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings, getMe, profile }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = () => useContext(StateContext);